import { useTranslation } from 'react-i18next'
import Title from '../../components/global/Title'
import { useVerifyAdminToken } from '../../hooks/useVerifyAdminToken'

const AdminDashboard = () => {
    const { t } = useTranslation('translation')
    const verifyToken = useVerifyAdminToken()

    verifyToken()
    return (
        <div>
            <Title>{t('admin.dashboard')}</Title>
        </div>
    )
}

export default AdminDashboard
