import styled from '@emotion/styled'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import axios from 'axios'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Breakpoint from '../../../Breakpoint'
import Label from '../../../components/form/Label'
import catchError from '../../../components/global/CatchError'
import { DropZoneCustom } from '../../../components/global/DropZoneBVA'
import FlexColumn from '../../../components/global/FlexColumn'
import Loader from '../../../components/global/Loader'
import { TextAreaWysiwyg } from '../../../components/global/TextAreaWysiwyg'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { serverFile } from '../../../services/file.service'
import { StyledButton } from '../Login'
import { AdminContainer, TitleWrapper } from '../users/AdminUsers'
import { AdminForm } from '../users/AdminUsersForm'
import { urlRegex } from '../../../utils/global-function'

const AdminCompetitionForm = () => {
    const user = useContext(UserContext)
    const location = useLocation()
    const params = useParams()

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentLang, setCurrentLang] = useState(lng)

    const navigate = useNavigate()
    const BACK_LINK = `/${lng}/bva-admin/competitions`

    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState<File[]>([])
    const [currentImage, setCurrentImage] = useState<string | undefined>('')
    const [linkHelloAsso, setLinkHelloAsso] = useState<string | undefined>('')
    const [linkBadnet, setLinkBadnet] = useState<string | undefined>('')
    const [minPrice, setMinPrice] = useState<number>(0)
    const [limitDateBadnet, setLimitDateBadnet] = useState<string | null>(null)
    const [limitDateHelloAsso, setLimitDateHelloAsso] = useState<string | null>(null)

    const [isLoading, setIsLoading] = useState(true)

    const isAddForm = location.pathname.includes('/bva-admin/competitions/creer')

    useEffect(() => {
        verifyToken()
        if ((!isAddForm && isLoading) || currentLang !== lng) {
            setCurrentLang(lng)
            axios
                .post(`${SERVER_URL}/competitions/one/${params.id}`, { lng }, apiHeader(user.tokenAdmin))
                .then((res) => {
                    setTitle(res.data.title)
                    setSubtitle(res.data.subtitle)
                    setDescription(res.data.description)
                    setCurrentImage(serverFile(res.data.image))
                    setLinkBadnet(res.data.linkBadnet)
                    setLinkHelloAsso(res.data.linkHelloAsso)
                    setMinPrice(res.data.minPrice)
                    if (res.data.limitDateBadnet)
                        setLimitDateBadnet(dayjs(res.data.limitDateBadnet).add(-1, 'day').format())
                    if (res.data.limitDateHelloAsso)
                        setLimitDateHelloAsso(dayjs(res.data.limitDateHelloAsso).add(-1, 'day').format())
                    setIsLoading(false)
                })
                .catch((err) => catchError(err.response.data))
        } else {
            setIsLoading(false)
        }
    }, [isAddForm, isLoading, user.tokenAdmin, params.id, lng])

    const isTitleValidate = title
    const isSubtitleValidate = subtitle
    const isDescriptionValidate = description
    const isLinkHelloAssoValidate = !linkHelloAsso || (linkHelloAsso.length > 0 && urlRegex.test(linkHelloAsso))
    const isLinkBadnetValidate = !linkBadnet || (linkBadnet.length > 0 && urlRegex.test(linkBadnet))
    const isMinPriceValidate = minPrice
    const isLimitDateBadnetValidate = linkBadnet && limitDateBadnet

    const isValidate =
        isTitleValidate &&
        isSubtitleValidate &&
        isDescriptionValidate &&
        isLinkHelloAssoValidate &&
        isLinkBadnetValidate &&
        isMinPriceValidate &&
        isLimitDateBadnetValidate

    if (isLoading) return <Loader />
    const submit = () => {
        verifyToken()

        const formData = new FormData()

        formData.append('title', title)
        formData.append('subtitle', subtitle)
        formData.append('description', description)
        formData.append('image', image?.[0] || currentImage)
        formData.append('linkHelloAsso', linkHelloAsso ?? '')
        formData.append('linkBadnet', linkBadnet ?? '')
        formData.append('minPrice', minPrice + '')

        if (isAddForm) {
            limitDateBadnet && formData.append('limitDateBadnet', dayjs(limitDateBadnet).add(1, 'day').format() + '')
            limitDateHelloAsso &&
                formData.append('limitDateHelloAsso', dayjs(limitDateHelloAsso).add(1, 'day').format() + '')
            axios
                .post(`${SERVER_URL}/competitions`, formData, apiHeader(user.tokenAdmin, true))
                .then(() =>
                    sweetAlertSuccess(`La compétition a bien été créé.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            formData.append('lng', lng)

            limitDateBadnet && formData.append('limitDateBadnet', dayjs(limitDateBadnet).add(1, 'day').format() + '')
            limitDateHelloAsso &&
                formData.append('limitDateHelloAsso', dayjs(limitDateHelloAsso).add(1, 'day').format() + '')

            axios
                .patch(`${SERVER_URL}/competitions/${params.id}`, formData, apiHeader(user.tokenAdmin, true))
                .then(() =>
                    sweetAlertSuccess(`La compétition a bien été modifié.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        }
    }

    return (
        <>
            <TitleWrapper>
                <Title>{isAddForm ? 'Créer' : 'Modifier'} une compétition</Title>
                <StyledButtonLink to={BACK_LINK} className="btn btn-primary mb-2">
                    Retour
                </StyledButtonLink>
            </TitleWrapper>
            <AdminContainer>
                <AdminForm>
                    <Form.Group className="mb-3">
                        <Label>Titre de la compétition</Label>
                        <Form.Control
                            type="text"
                            placeholder="Titre de la compétition"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Sous titre</Label>
                        <Form.Control
                            type="text"
                            placeholder="Sous titre"
                            onChange={(e) => setSubtitle(e.target.value)}
                            value={subtitle}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Prix minimal du tournoi</Label>
                        <Form.Control
                            type="text"
                            placeholder="Prix minimal du tournoi"
                            onChange={(e) => setMinPrice(+e.target.value)}
                            value={minPrice}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Description</Label>
                        <TextAreaWysiwyg value={description} setValue={setDescription} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Label>Image</Label>
                        <ImageWrapper>
                            <DropZoneCustom setImage={setImage} image={image} currentImage={currentImage} />
                        </ImageWrapper>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Label>Lien BadNet *</Label>
                        <Form.Control
                            type="text"
                            placeholder="Lien BadNet"
                            onChange={(e) => setLinkBadnet(e.target.value)}
                            value={linkBadnet}
                        />
                    </Form.Group>
                    {linkBadnet && (
                        <Form.Group className="mb-3">
                            <FlexColumn>
                                <Label>Date limite d'inscription Badnet * </Label>
                                <DatePicker
                                    label="Date limite d'inscription Badnet"
                                    value={dayjs(limitDateBadnet).toString()}
                                    onChange={(newValue) => {
                                        setLimitDateBadnet(newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    inputFormat="dd/MM/yyyy"
                                    className="datepicker"
                                    disablePast
                                />
                            </FlexColumn>
                        </Form.Group>
                    )}

                    <Form.Group className="mb-3">
                        <Label>Lien HelloAsso</Label>
                        <Form.Control
                            type="text"
                            placeholder="Lien HelloAsso"
                            onChange={(e) => setLinkHelloAsso(e.target.value)}
                            value={linkHelloAsso}
                        />
                    </Form.Group>

                    {linkHelloAsso && (
                        <Form.Group className="mb-3">
                            <FlexColumn>
                                <Label>Date limite d'inscription HelloAsso</Label>
                                <DatePicker
                                    label="Date limite d'inscription HelloAsso"
                                    value={dayjs(limitDateHelloAsso).toString() as any}
                                    onChange={(newValue: any) => {
                                        setLimitDateHelloAsso(newValue)
                                    }}
                                    renderInput={(params: any) => <TextField {...params} />}
                                    inputFormat="dd/MM/yyyy"
                                    disablePast
                                />
                            </FlexColumn>
                        </Form.Group>
                    )}
                    <StyledButton variant="primary" type="button" disabled={!isValidate} onClick={submit}>
                        {isAddForm ? 'Créer' : 'Modifier'}
                    </StyledButton>
                </AdminForm>
            </AdminContainer>
        </>
    )
}

const CurrentImagePreview = styled.div`
    display: flex;
    width: 36%;
    height: 250px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 16px;
    text-align: center;

    & img {
        width: 128px;
        height: 128px;
    }

    @media (max-width: ${Breakpoint.TABLET_L}) {
        margin-top: 16px;
        width: 100%;
        margin-left: 0;
    }
`

const ImageWrapper = styled.div`
    display: flex;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        flex-direction: column;
    }
`

export default AdminCompetitionForm
