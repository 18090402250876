import axios, { AxiosResponse } from 'axios'
import { useContext, useEffect, useState } from 'react'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { CoachSessionModel, SessionModel } from '../../../types/session.type'
import { AdminContainer, LinkBtn, TitleWrapper } from '../users/AdminUsers'

const filteredSession = (sessions: SessionModel[], res: AxiosResponse<any, any>) =>
    sessions.filter((session) => session.id !== res.data.id)

const AdminSession = () => {
    const user = useContext(UserContext)
    const [sessions, setSessions] = useState<SessionModel[]>()

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentLang, setCurrentLang] = useState(lng)

    const tableHeader = ['#', 'Nom de l’entraînement', 'Prénom du coach', '', '']

    useEffect(() => {
        verifyToken()
        if (!sessions || currentLang !== lng) {
            setCurrentLang(lng)
            axios
                .post(`${SERVER_URL}/sessions/all`, { lng }, apiHeader(user.tokenAdmin))
                .then(({ data }) => {
                    setSessions(
                        data.map((coachSession: CoachSessionModel) => ({
                            id: coachSession.session.id,
                            name: coachSession.session.name,
                            coachName: coachSession.coach.firstName,
                            edit: `/${lng}/bva-admin/sessions/${coachSession.session.id}`,
                            remove: true,
                        }))
                    )
                })
                .catch((err) =>
                    catchError(err.response.data, () => {
                        localStorage.removeItem('tokenAdmin')
                        user.adminData = undefined
                        window.location.reload()
                    })
                )
        }
    }, [sessions, user, lng])

    if (!sessions) return <Loader />

    const deleteById = (id: number) => {
        verifyToken()
        axios
            .delete(`${SERVER_URL}/sessions/${id}`, apiHeader(user.tokenAdmin))
            .then((res) => {
                sweetAlertSuccess('La suppression de cet session est réussi').then(() => {
                    setSessions([...filteredSession(sessions, res)])
                })
            })
            .catch((err) =>
                catchError(err.response.data, () => {
                    localStorage.removeItem('tokenAdmin')
                    user.adminData = undefined
                    window.location.reload()
                })
            )
    }

    return (
        <>
            <TitleWrapper>
                <Title>Sessions d'entraînement</Title>
                <LinkBtn to={`/${lng}/bva-admin/sessions/creer`} className="btn btn-primary mb-2">
                    Créer
                </LinkBtn>
            </TitleWrapper>
            <AdminContainer>
                <Table tableData={sessions} tableHeader={tableHeader} deleteById={deleteById} />
            </AdminContainer>
        </>
    )
}

export default AdminSession
