import styled from '@emotion/styled'
import { faBook, faDumbbell, faHomeLg, faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Breakpoint from '../../../Breakpoint'
import { useLangSelected } from '../../../hooks/useLangSelected'
import AdminContainer from '../../admin/AdminContainer'
import MainLink from '../MainLink'

export const MemberSideBar = ({ children }: { children: ReactNode }) => {
    const location = useLocation()

    let lng = useLangSelected()

    const { t } = useTranslation()

    return (
        <>
            <Sidebar>
                <SidebarLink
                    to={`${lng}/bva-membre`}
                    className={location.pathname === `/${lng}/bva-membre` ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faHomeLg} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.dashboard')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-membre/documentation`}
                    className={location.pathname === `/${lng}/bva-membre/documentation` ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faBook} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.admin.documentation')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-membre/ma-progression`}
                    className={location.pathname === `/${lng}/bva-membre/ma-progression` ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faDumbbell} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.member.my-progression')}
                </SidebarLink>
                <SidebarLink
                    to={`/${lng}/bva-membre/modifier-mon-mot-de-passe`}
                    className={location.pathname === `/${lng}/bva-membre/modifier-mon-mot-de-passe` ? 'active' : ''}
                >
                    <FontAwesomeIcon icon={faKey} />{' '}
                    {window.innerWidth > +Breakpoint.LAPTOP_XS.split('p')[0] && t('sidebar.member.password')}
                </SidebarLink>
            </Sidebar>
            <AdminContainer>{children}</AdminContainer>
        </>
    )
}

const SidebarLink = styled(MainLink)`
    display: flex;
    align-items: center;

    &.active {
        border: none;
    }

    & * {
        margin-right: 8px;

        @media (max-width: ${Breakpoint.TABLET_L}) {
            justify-content: center;
            margin-right: 0;
        }

        @media (max-width: ${Breakpoint.PHONE}) {
            height: 3rem;
        }
    }

    &:active {
        transform: scale(0.95);
    }

    @media (max-width: ${Breakpoint.TABLET_L}) {
        justify-content: center;
    }
`

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    width: 15%;
    background-color: white;
    min-height: calc(100vh - 166px);
    box-shadow: 0px 0px 4px black;
    box-sizing: border-box;
    padding: 16px;
    padding-left: 3%;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        padding: 24px 0;
    }
`

export default MemberSideBar
