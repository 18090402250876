import styled from '@emotion/styled'
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown, faChevronUp, faPen, faStar, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiHeader, SERVER_URL } from '../../config'
import UserContext from '../../context/UserContext'
import { SkillStatusEnum } from '../../types/skill.type'

type SkillProps = {
    id?: number
    name: string
    comment?: string
    status?: SkillStatusEnum
    isFavorite?: boolean
    refetchMySkills?: () => void
    redirect?: () => void
    isInWhiteBackground?: true
    deleteFn?: () => void
    editFn?: () => void
}

export const skillStatusColors: Record<SkillStatusEnum, string> = {
    [SkillStatusEnum.TO_DISCOVER]: '#aaaaaa',
    [SkillStatusEnum.STARTED]: '#FFC107',
    [SkillStatusEnum.CONFIRMED]: '#8BC34A',
    [SkillStatusEnum.MASTERED]: '#337AB7',
}

const SkillCard = ({
    id,
    name,
    comment,
    status,
    isFavorite,
    refetchMySkills,
    isInWhiteBackground,
    redirect,
    deleteFn,
    editFn,
}: SkillProps) => {
    const user = useContext(UserContext)
    const [isOpen, setIsOpen] = useState(false)

    const { mutate: skillIsFavoriteMutation } = useMutation({
        mutationFn: (isFavorite: boolean) =>
            axios
                .patch(
                    `${SERVER_URL}/skills/users/${id}`,
                    {
                        isFavorite,
                    },
                    apiHeader(user.tokenMember)
                )
                .then(() => refetchMySkills?.()),
    })

    const { t } = useTranslation('translation')

    return (
        <SkillCardStyled
            onClick={() => setIsOpen(!isOpen)}
            isClickable={!!comment}
            isInWhiteBackground={isInWhiteBackground}
        >
            <TitleWrapper>
                <SkillCardTitle
                    isClickable={!!redirect}
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        redirect?.()
                    }}
                >
                    {name}
                </SkillCardTitle>
                <BadgeContainer>
                    {status && <Badge backgroundColor={skillStatusColors[status]}>{t(`skill.${status}`)}</Badge>}

                    {isFavorite !== undefined && (
                        <FavoriteBtn
                            onClick={(e) => {
                                e.stopPropagation()
                                skillIsFavoriteMutation(!isFavorite)
                            }}
                        >
                            <FontAwesomeIcon icon={isFavorite ? faStar : faStarRegular} />
                        </FavoriteBtn>
                    )}
                    {editFn && <EditIcon icon={faPen} onClick={editFn} />}
                    {deleteFn && <TrashIcon icon={faTrash} onClick={deleteFn} />}
                    {comment && (
                        <DropDownIcon>
                            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                        </DropDownIcon>
                    )}
                </BadgeContainer>
            </TitleWrapper>

            {isOpen && comment && (
                <SkillDropdown>
                    Commentaire<SkillDropdownItem>{comment}</SkillDropdownItem>
                </SkillDropdown>
            )}
        </SkillCardStyled>
    )
}

const EditIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    margin-right: 16px;
    background-color: var(--bs-btn-hover-bg);
    scale: 1.5;
`

const TrashIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: var(--primary-color);
    scale: 1.5;
`

const FavoriteBtn = styled.button`
    background-color: transparent;
    border: none;
    appearance: none;
    cursor: pointer;
`

const BadgeContainer = styled.div`
    display: flex;
    align-items: center;
`

const DropDownIcon = styled.div`
    margin-left: 8px;
`

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SkillCardStyled = styled.div<{ isClickable?: boolean; isInWhiteBackground?: true }>`
    position: relative;
    ${({ isClickable }) => (isClickable ? 'cursor: pointer;' : '')}

    ${({ isInWhiteBackground }) => (isInWhiteBackground ? 'background-color: #f7f7f7;' : 'background-color: white;')}
    ${({ isInWhiteBackground }) => (isInWhiteBackground ? 'border: 1px solid #ddd;' : '')}
    
    border-radius: 5px;
    padding: 20px;
    height: min-content;

    &:hover {
        ${({ isClickable }) => (isClickable ? 'background-color: #eee;' : '')}
    }
`

const SkillCardTitle = styled.div<{ isClickable?: boolean }>`
    ${({ isClickable }) => (isClickable ? 'cursor: pointer;' : '')}
    ${({ isClickable }) => (isClickable ? 'text-decoration: underline;' : '')}

    font-weight: bold;
    margin-right: 8px;

    &:hover {
        ${({ isClickable }) => (isClickable ? 'color: #0066cc;' : '')}
    }
`

const SkillDropdown = styled.div`
    position: relative;
    padding: 10px;
`

const SkillDropdownItem = styled.p`
    border-top: 1px solid #ccc;
    margin-top: 8px;
    padding: 10px;
`

const Badge = styled.span<{ backgroundColor?: string }>`
    user-select: none;
    background-color: ${(props) => props.backgroundColor ?? '#0066cc'};
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 8px;
`

export default SkillCard
