import { Context, createContext } from 'react'
import { UserContextType } from '../types/user.type'

const UserContext: Context<UserContextType> = createContext<UserContextType>({
    tokenAdmin: '',
    adminData: undefined,
    tokenMember: '',
    memberData: undefined,
    tokenCoach: '',
    coachData: undefined,
})

export default UserContext
