import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CoachMemberCard from '../../components/coach/CoachMemberCard'
import Loader from '../../components/global/Loader'
import SkillCard from '../../components/global/SkillCard'
import Title from '../../components/global/Title'
import { SERVER_URL, apiHeader } from '../../config'
import UserContext from '../../context/UserContext'
import { CoachModel } from '../../types/coach.type'
import { SkillStatusEnum } from '../../types/skill.type'
import { SkillsWrapper } from './my-members/CoachMyMembers'

const CoachDashboard = () => {
    const user = useContext(UserContext)
    const { t } = useTranslation('translation')

    const { data: coach } = useQuery({
        queryKey: ['coach-users'],
        queryFn: () =>
            axios
                .get<CoachModel>(`${SERVER_URL}/coachs/${user.coachData.id}`, apiHeader(user.tokenCoach))
                .then((res) => res.data),
    })

    const coachSkills = useMemo(
        () =>
            !coach
                ? []
                : [...coach.users]
                      .map((user) => user.skills)
                      .flat()
                      .reduce((acc, skill) => {
                          const existingSkill = acc.find((s) => s.name === skill.name)
                          if (existingSkill) {
                              existingSkill.nombre++
                          } else {
                              acc.push({ name: skill.name, nombre: 1 })
                          }
                          return acc
                      }, [] as { name: string; nombre: number }[]),
        [coach]
    )

    if (!user.coachData || !coach || !coachSkills) return <Loader />

    return (
        <div>
            <Title>{t('coach.dashboard')}</Title>
            <PlayersWrapper>
                <Title>{t('coach.4-players-with-most-missings')}</Title>
                <SkillsWrapper>
                    {[...coach.users]
                        .sort(
                            (a, b) =>
                                a.skills.filter((skill) => skill.status === SkillStatusEnum.TO_DISCOVER).length -
                                b.skills.filter((skill) => skill.status === SkillStatusEnum.TO_DISCOVER).length
                        )
                        .slice(0, 4)
                        .map((user) => (
                            <CoachMemberCard key={user.id} user={user} />
                        ))}
                </SkillsWrapper>
            </PlayersWrapper>
            <PlayersWrapper>
                <Title>{t('coach.skills-least-mastered')}</Title>
                <SkillsWrapper>
                    {[...coachSkills]
                        .sort((a, b) => a.nombre - b.nombre)
                        .slice(0, 4)
                        .map((skill) => (
                            <SkillCard
                                key={skill.name}
                                name={skill.name}
                                status={SkillStatusEnum.TO_DISCOVER}
                                isInWhiteBackground
                            />
                        ))}
                </SkillsWrapper>
            </PlayersWrapper>
        </div>
    )
}

const PlayersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    border-radius: 6px;
    background-color: white;
    margin-bottom: 16px;
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    border-radius: 6px;
    background-color: white;
    margin-bottom: 16px;
`

export default CoachDashboard
