import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../components/global/Loader'
import SearchInput from '../../../components/global/SearchInput'
import SkillCard from '../../../components/global/SkillCard'
import Title from '../../../components/global/Title'
import FilterStatus from '../../../components/member/FilterStatus'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { SkillStatusEnum } from '../../../types/skill.type'
import { UserModel } from '../../../types/user.type'
import { TitleWrapper } from '../../admin/users/AdminUsers'
import { FilterWrapper } from '../../member/MemberMyProgression'
import { SkillsWrapper } from './CoachMyMembers'

const CoachMyMembersSingleMemberView = () => {
    const user = useContext(UserContext)
    const { t } = useTranslation('translation')

    const navigate = useNavigate()
    const { memberId } = useParams()

    const lng = useLangSelected()

    const [searchTerm, setSearchTerm] = useState('')
    const [selectedStatus, setSelectedStatus] = useState<SkillStatusEnum[]>([
        SkillStatusEnum.TO_DISCOVER,
        SkillStatusEnum.STARTED,
        SkillStatusEnum.CONFIRMED,
        SkillStatusEnum.MASTERED,
    ])

    const { data: member } = useQuery({
        queryKey: ['coach-member'],
        queryFn: () =>
            axios.get<UserModel>(`${SERVER_URL}/users/${memberId}`, apiHeader(user.tokenCoach)).then((res) => res.data),
    })

    const skillsFiltered = useMemo(
        () =>
            member?.skills
                ?.filter((skill) => `${skill.name}`.toLowerCase().includes(searchTerm.toLowerCase()))
                .filter((skill) => selectedStatus.includes(skill.status)) ?? [],
        [searchTerm, member, selectedStatus]
    )

    if (!member) return <Loader />

    return (
        <div>
            <TitleWrapper>
                <Title>
                    {member.firstName} {member.lastName}
                </Title>

                <StyledButtonLink to={`/${lng}/bva-coach/mes-membres`} className="btn btn-primary mb-2">
                    {t('global.back')}
                </StyledButtonLink>
            </TitleWrapper>
            <FilterWrapper>
                <SearchInput
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={t('member.search-skill-input')}
                />
                <FilterStatus selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
            </FilterWrapper>
            <SkillsWrapper>
                {skillsFiltered.map((skill) => (
                    <SkillCard
                        key={skill.id}
                        name={skill.name}
                        status={skill.status}
                        comment={skill.comment}
                        redirect={() => navigate(`/${lng}/bva-coach/mes-membres/${member.id}/${skill.id}`)}
                    />
                )) ?? ''}
            </SkillsWrapper>
        </div>
    )
}

export default CoachMyMembersSingleMemberView
