import styled from '@emotion/styled'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import 'bootstrap/dist/css/bootstrap.min.css'
import { jwtDecode } from 'jwt-decode'
import { useMemo } from 'react'
import { ToastContainer } from 'react-toastify'
import './assets/theme.css'
import Router from './components/global/Router'
import UserContext from './context/UserContext'
import './i18n/translation'
import { DecodedToken, UserContextType } from './types/user.type'

function App() {
    const tokenAdmin = localStorage.getItem('tokenAdmin') ?? ''
    const decodedTokenAdmin: DecodedToken | undefined = tokenAdmin ? jwtDecode(tokenAdmin) : undefined
    const tokenMember = localStorage.getItem('tokenMember') ?? ''
    const decodedTokenMember: DecodedToken | undefined = tokenMember ? jwtDecode(tokenMember) : undefined
    const tokenCoach = localStorage.getItem('tokenCoach') ?? ''
    const decodedTokenCoach: DecodedToken | undefined = tokenCoach ? jwtDecode(tokenCoach) : undefined

    const user: UserContextType = useMemo(
        () => ({
            tokenAdmin,
            adminData: decodedTokenAdmin,
            tokenMember,
            memberData: decodedTokenMember,
            tokenCoach,
            coachData: decodedTokenCoach,
        }),
        [tokenAdmin, tokenMember, decodedTokenAdmin, decodedTokenMember]
    )

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ToastContainer />
            <MainContainer>
                <UserContext.Provider value={user}>
                    <Router />
                </UserContext.Provider>
            </MainContainer>
        </LocalizationProvider>
    )
}

const MainContainer = styled.div`
    background: var(--main-bg-color);
    min-height: 100vh;
`

export default App
