import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import catchError from '../components/global/CatchError'
import { disconnect } from '../components/global/header/Header'
import { isTokenExpired } from '../services/jwt.service'
import { useLangSelected } from './useLangSelected'

export function useVerifyCoachToken() {
    const tokenCoach = localStorage.getItem('tokenCoach') ?? ''
    const navigate = useNavigate()
    const lng = useLangSelected()
    const { t } = useTranslation('translation')

    const verifyToken = () => {
        if (isTokenExpired(tokenCoach.split('Bearer ')[1]))
            catchError({ status: 401, message: t('error.session-expired') }, () => {
                disconnect()
                navigate(`/${lng}/login`)
            })
    }

    return verifyToken
}
