import styled from '@emotion/styled'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Breakpoint from '../../../Breakpoint'
import Label from '../../../components/form/Label'
import catchError from '../../../components/global/CatchError'
import { DropZoneCustom } from '../../../components/global/DropZoneBVA'
import Loader from '../../../components/global/Loader'
import { TextAreaWysiwyg } from '../../../components/global/TextAreaWysiwyg'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import { StyledButtonLink } from '../../../components/table/Td'
import { apiHeader, SERVER_URL } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { serverFile } from '../../../services/file.service'
import { NewsType } from '../../../types/news.type'
import { urlRegex } from '../../../utils/global-function'
import { StyledButton } from '../Login'
import { AdminContainer, TitleWrapper } from '../users/AdminUsers'
import { AdminForm } from '../users/AdminUsersForm'

const AdminNewsForm = () => {
    const user = useContext(UserContext)
    const location = useLocation()
    const params = useParams()

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentLang, setCurrentLang] = useState(lng)

    const navigate = useNavigate()
    const BACK_LINK = `/${lng}/bva-admin/articles`

    const [title, setTitle] = useState('')
    const [type, setType] = useState('placeholder')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState<File[]>([])
    const [currentImage, setCurrentImage] = useState<string | undefined>('')
    const [link, setLink] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const isAddForm = location.pathname.includes('/bva-admin/articles/creer')

    useEffect(() => {
        verifyToken()
        if ((!isAddForm && isLoading) || currentLang !== lng) {
            setCurrentLang(lng)
            axios
                .post(`${SERVER_URL}/news/one/${params.id}`, { lng }, apiHeader(user.tokenAdmin))
                .then((res) => {
                    setTitle(res.data.title)
                    setType(res.data.type)
                    setDescription(res.data.description)
                    setCurrentImage(serverFile(res.data.image))
                    setLink(res.data.link)
                    setIsLoading(false)
                })
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            setIsLoading(false)
        }
    }, [isAddForm, isLoading, user.tokenAdmin, params.id, lng])

    if (isLoading || currentLang !== lng) return <Loader />

    const isTitleValidate = title.length > 2
    const isTypeValidate = type !== 'placeholder'
    const isDescriptionValidate = description.length > 2
    const isLinkValidate = !link || (link.length > 0 && urlRegex.test(link))

    const isValidate = isTitleValidate && isDescriptionValidate && isLinkValidate && isTypeValidate

    const submit = () => {
        verifyToken()
        const formData = new FormData()
        formData.append('title', title)
        formData.append('type', type)
        formData.append('description', description)
        formData.append('image', image?.[0] || currentImage)
        formData.append('link', link)

        if (isAddForm) {
            axios
                .post(`${SERVER_URL}/news`, formData, apiHeader(user.tokenAdmin, true))
                .then(() =>
                    sweetAlertSuccess(`L'article a bien été créé.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            formData.append('lng', lng)

            axios
                .patch(`${SERVER_URL}/news/${params.id}`, formData, apiHeader(user.tokenAdmin, true))
                .then(() =>
                    sweetAlertSuccess(`L'article a bien été modifié.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        }
    }

    return (
        <>
            <TitleWrapper>
                <Title>{isAddForm ? 'Créer' : 'Modifier'} un article</Title>
                <StyledButtonLink to={BACK_LINK} className="btn btn-primary mb-2">
                    Retour
                </StyledButtonLink>
            </TitleWrapper>
            <AdminContainer>
                <AdminForm>
                    <Form.Group className="mb-3">
                        <Label>Titre de l'article *</Label>
                        <Form.Control
                            type="text"
                            placeholder="Titre de l'article"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Type de l'article *</Label>
                        <Form.Select onChange={(e) => setType(e.target.value)} defaultValue={type || 'placeholder'}>
                            <option value={'placeholder'} disabled>
                                Sélectionnez un type
                            </option>
                            <option value="MEETING">Réunion</option>
                            <option value="PRIVATE">Privée</option>
                            <option value="PUBLIC">Publique</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Description *</Label>
                        <TextAreaWysiwyg value={description} setValue={setDescription} />
                    </Form.Group>
                    {type !== NewsType.MEETING && (
                        <Form.Group className="mb-3">
                            <Label>Image</Label>
                            <ImageWrapper>
                                <DropZoneCustom setImage={setImage} image={image} currentImage={currentImage} />
                            </ImageWrapper>
                        </Form.Group>
                    )}
                    <Form.Group className="mb-3">
                        <Label>Lien</Label>
                        <Form.Control
                            type="text"
                            placeholder="Lien"
                            onChange={(e) => setLink(e.target.value)}
                            value={link}
                        />
                    </Form.Group>
                    <StyledButton variant="primary" type="button" disabled={!isValidate} onClick={submit}>
                        {isAddForm ? 'Créer' : 'Modifier'}
                    </StyledButton>
                </AdminForm>
            </AdminContainer>
        </>
    )
}

const CurrentImagePreview = styled.div`
    display: flex;
    width: 36%;
    height: 250px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 16px;
    text-align: center;

    & img {
        width: 128px;
        height: 128px;
    }

    @media (max-width: ${Breakpoint.TABLET_L}) {
        margin-top: 16px;
        width: 100%;
        margin-left: 0;
    }
`

const ImageWrapper = styled.div`
    display: flex;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        flex-direction: column;
    }
`

export default AdminNewsForm
