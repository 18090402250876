import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Label from '../../../components/form/Label'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { CoachModel } from '../../../types/coach.type'
import { StyledButton } from '../Login'
import { AdminContainer, TitleWrapper } from '../users/AdminUsers'
import { AdminForm } from '../users/AdminUsersForm'

const AdminSessionsForm = () => {
    const user = useContext(UserContext)
    const location = useLocation()
    const params = useParams()

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentLang, setCurrentLang] = useState(lng)

    const navigate = useNavigate()
    const BACK_LINK = `/${lng}/bva-admin/sessions`

    const [name, setName] = useState('')
    const [coachId, setCoachId] = useState('placeholder')
    const [coachs, setCoachs] = useState<CoachModel[]>([])

    const [isCoachLoading, setIsCoachLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(true)

    const isAddForm = location.pathname.includes('/bva-admin/sessions/creer')

    useEffect(() => {
        verifyToken()

        axios
            .get(`${SERVER_URL}/coachs`, apiHeader(user.tokenAdmin))
            .then((res) => {
                setCoachs(res.data)
                setIsCoachLoading(false)
            })
            .catch((err) => catchError(err.response.data))

        if ((!isAddForm && isLoading) || currentLang !== lng) {
            setCurrentLang(lng)

            axios
                .post(`${SERVER_URL}/sessions/one/${params.id}`, { lng }, apiHeader(user.tokenAdmin))
                .then((res) => {
                    setName(res.data.session.name)
                    setCoachId(res.data.coach.id)
                    setIsLoading(false)
                })
                .catch((err) => catchError(err.response.data))
        } else {
            setIsLoading(false)
            setIsCoachLoading(false)
        }
    }, [isAddForm, isLoading, isCoachLoading, user.tokenAdmin, params.id, lng])

    if (isLoading || isCoachLoading) return <Loader />

    const isValidate = name && coachId !== 'placeholder'

    const submit = () => {
        verifyToken()
        let data: any = {
            name,
            coachId,
        }

        if (isAddForm) {
            axios
                .post(`${SERVER_URL}/sessions`, data, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`Le session a bien été créé.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            data = {
                ...data,
                lng,
            }

            axios
                .patch(`${SERVER_URL}/sessions/${params.id}`, data, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`Le session a bien été modifié.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        }
    }

    return (
        <>
            <TitleWrapper>
                <Title>{isAddForm ? 'Créer' : 'Modifier'} une session d’entraînement</Title>
                <StyledButtonLink to={BACK_LINK} className="btn btn-primary mb-2">
                    Retour
                </StyledButtonLink>
            </TitleWrapper>
            <AdminContainer>
                <AdminForm>
                    <Form.Group className="mb-3">
                        <Label>Titre de la session*</Label>
                        <Form.Control
                            type="text"
                            placeholder="Titre de la session*"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Label>Coach *</Label>
                        <Form.Select defaultValue={coachId} onChange={(e) => setCoachId(e.target.value)}>
                            <option value="placeholder" disabled>
                                Sélectionne le coach
                            </option>
                            {coachs?.map((coach) => (
                                <option key={coach.id} value={coach.id}>
                                    {coach.firstName}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <StyledButton variant="primary" type="button" disabled={!isValidate} onClick={submit}>
                        {isAddForm ? 'Créer' : 'Modifier'}
                    </StyledButton>
                </AdminForm>
            </AdminContainer>
        </>
    )
}

export default AdminSessionsForm
