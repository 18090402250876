import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { StyledButton } from '../../pages/admin/Login'
import { roles } from '../../types/user.type'

type TdType = {
    singleData: any[]
    deleteById?: (id: number) => void
    id: number
}

const roleText = (role: string) => {
    return role === roles.ROLE_ADMIN ? 'Administrateur' : 'Adhérent'
}

const actionText = (action: { redirection: string }) => {
    return action?.redirection ? `Redirection : ${action.redirection}` : ''
}

const Td = ({ singleData, id, deleteById }: TdType) => {
    if (singleData[0] === 'remove' && typeof singleData[1] === 'function')
        return (
            <SmallTd>
                <StyledButton variant="danger" type="button" onClick={() => singleData[1]()}>
                    Supprimer
                </StyledButton>
            </SmallTd>
        )
    else if (singleData[0] === 'remove')
        return (
            <SmallTd>
                <StyledButton variant="danger" type="button" onClick={() => deleteById && deleteById(id)}>
                    Supprimer
                </StyledButton>
            </SmallTd>
        )
    else if (Array.isArray(singleData) && singleData[0] === 'render') return <CustomTd>{singleData[1]}</CustomTd>
    else if (Array.isArray(singleData) && singleData[0] === 'edit' && typeof singleData[1] === 'function')
        return (
            <SmallTd>
                <StyledButton variant="primary" onClick={() => singleData[1]()}>
                    Modifier
                </StyledButton>
            </SmallTd>
        )
    else if (Array.isArray(singleData) && singleData[0] === 'edit')
        return (
            <SmallTd>
                <StyledButtonLink to={singleData[1]} className="btn btn-primary">
                    Modifier
                </StyledButtonLink>
            </SmallTd>
        )
    else if (Array.isArray(singleData) && singleData[0] === 'image' && singleData[1] !== null)
        return (
            <CustomTd>
                <a href={singleData[1]} target="_blank" rel="noreferrer">
                    <Img src={singleData[1]} alt={`${singleData[1]}`} />
                </a>
            </CustomTd>
        )
    else if (singleData[0] === 'id') return <SmallTd>{singleData[1]}</SmallTd>
    else if (singleData[0] === 'description')
        return (
            <CustomTd>
                <Content>{singleData[1]}</Content>
            </CustomTd>
        )
    else if (singleData[0] === 'role') return <CustomTd>{roleText(singleData[1])}</CustomTd>
    else if (singleData[0] === 'action') return <CustomTd>{actionText(singleData[1])}</CustomTd>

    return <CustomTd>{singleData[1]}</CustomTd>
}

export const StyledButtonLink = styled(Link)`
    font-size: 1.6rem;

    display: flex;
    align-items: center;

    &:active {
        transform: scale(0.95);
    }
`

const Content = styled.div`
    height: 100px;
    overflow: auto;
    padding-right: 13px;
`

const CustomTd = styled.td`
    border-bottom: 1px solid black;
    vertical-align: middle;
`

const SmallTd = styled(CustomTd)`
    width: 0;
`

const Img = styled.img`
    height: 38px;
`

export default Td
