import styled from '@emotion/styled'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectSearch from 'react-select-search'
import 'react-select-search/style.css'
import { toast } from 'react-toastify'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { apiHeader, SERVER_URL } from '../../../config'
import UserContext from '../../../context/UserContext'
import { CoachModel } from '../../../types/coach.type'
import { roles, UserModel } from '../../../types/user.type'

const CoachMyMembersAddMemberForm = () => {
    const user = useContext(UserContext)

    const [usersSelected, setUsersSelected] = useState<number[]>([])
    const { t } = useTranslation('translation')

    const { data: users } = useQuery({
        queryKey: ['coach-my-members-users'],
        queryFn: () =>
            axios
                .get<UserModel[]>(`${SERVER_URL}/users?role=${roles.ROLE_MEMBER}`, apiHeader(user.tokenCoach))
                .then((res) => res.data),
    })

    const { refetch: refetchCoach } = useQuery({
        queryKey: ['coach'],
        queryFn: () =>
            axios
                .get<CoachModel>(`${SERVER_URL}/coachs/${user.coachData.id}`, apiHeader(user.tokenCoach))
                .then((res) => {
                    setUsersSelected(res.data.users.map((user) => user.id))
                    return res.data
                }),
    })

    const { mutate: updateCoachUsers } = useMutation({
        mutationFn: ({ usersSelectedToSend, add }: { usersSelectedToSend: number[]; add?: boolean }) => {
            return axios
                .patch(
                    `${SERVER_URL}/coachs/${user.coachData.id}`,
                    { usersIds: usersSelectedToSend },
                    apiHeader(user.tokenCoach)
                )
                .then(() => {
                    if (add) toast.success(t('coach.members-added'))
                    else toast.success(t('coach.members-removed'))

                    refetchCoach()
                })
        },
    })

    const options = useMemo(
        () => users?.map((user: UserModel) => ({ name: `${user.firstName} ${user.lastName}`, value: user.id })) ?? [],
        [users]
    )

    if (!users) return <Loader />

    return (
        <div>
            <Title>{t('coach.select-members')}</Title>
            <SelectSearchContainer key={'select-search' + usersSelected.length}>
                <SelectSearch
                    multiple
                    options={options}
                    placeholder="Sélectionner les membres"
                    onBlur={() => {}}
                    onFocus={() => {}}
                    search
                    renderOption={(_domOption, option) => {
                        const userIdFound = usersSelected.find((userId) => userId === option.value)

                        return (
                            <Option
                                key={option.value}
                                id={'' + option.value}
                                tabIndex={0}
                                isSelected={!!userIdFound}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (!userIdFound)
                                            updateCoachUsers({
                                                usersSelectedToSend: [...usersSelected, +option.value],
                                                add: true,
                                            })
                                        else
                                            updateCoachUsers({
                                                usersSelectedToSend: usersSelected.filter((id) => id !== userIdFound),
                                            })
                                    }
                                }}
                                onClick={() => {
                                    if (!userIdFound)
                                        updateCoachUsers({
                                            usersSelectedToSend: [...usersSelected, +option.value],
                                            add: true,
                                        })
                                    else
                                        updateCoachUsers({
                                            usersSelectedToSend: usersSelected.filter((id) => id !== userIdFound),
                                        })
                                }}
                            >
                                {option.name}
                            </Option>
                        )
                    }}
                />
            </SelectSearchContainer>
        </div>
    )
}

const SelectSearchContainer = styled.div`
    & .select-search-container {
        width: 100% !important;
    }

    & .select-search-select {
        max-height: unset;
    }
`

const Option = styled.div<{ isSelected: boolean }>`
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;

    ${({ isSelected }) => (isSelected ? 'background-color:#007a08;' : '')}
    ${({ isSelected }) => (isSelected ? 'color:white;' : '')}

    &:hover {
        ${({ isSelected }) => (isSelected ? 'background-color:#007a08aa;' : 'background-color: #ddd;')}
    }
`

export default CoachMyMembersAddMemberForm
