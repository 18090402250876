import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Breakpoint from '../../../Breakpoint'
import CoachMemberCard from '../../../components/coach/CoachMemberCard'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { CoachModel } from '../../../types/coach.type'
import { TitleWrapper } from '../../admin/users/AdminUsers'

const CoachMyMembers = () => {
    const user = useContext(UserContext)
    const { t } = useTranslation('translation')

    const lng = useLangSelected()

    const { data: coach } = useQuery({
        queryKey: ['coach-my-members'],
        queryFn: () =>
            axios
                .get<CoachModel>(`${SERVER_URL}/coachs/${user.coachData.id}`, apiHeader(user.tokenCoach))
                .then((res) => res.data),
    })

    if (!user.coachData || !coach) return <Loader />

    return (
        <div>
            <TitleWrapper>
                <Title>{t('coach.my-members')}</Title>
                <StyledButtonLink
                    to={`/${lng}/bva-coach/mes-membres/selectionner-des-membres`}
                    className="btn btn-primary"
                >
                    {t('coach.select-members')}
                </StyledButtonLink>
            </TitleWrapper>
            <SkillsWrapper>
                {coach.users.map((user) => <CoachMemberCard key={user.id} user={user} />) ?? ''}
            </SkillsWrapper>
        </div>
    )
}

export const SkillsWrapper = styled.div`
    display: grid;
    grid: auto-flow / 1fr 1fr 1fr 1fr;
    gap: 16px;
    margin-bottom: 16px;

    @media (max-width: ${Breakpoint.PHONE}) {
        grid: auto-flow / 1fr;
        margin-top: 16px;
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    border-radius: 6px;
    background-color: white;
    margin-bottom: 16px;
`

export default CoachMyMembers
