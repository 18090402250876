import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback, useContext, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Label from '../../../components/form/Label'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyCoachToken } from '../../../hooks/useVerifyCoachToken'
import { Skill, SkillStatusEnum } from '../../../types/skill.type'
import { UserModel } from '../../../types/user.type'
import { StyledButton } from '../../admin/Login'
import { AdminContainer, TitleWrapper } from '../../admin/users/AdminUsers'
import { AdminForm } from '../../admin/users/AdminUsersForm'

const CoachMyMembersSingleMemberSkillsForm = () => {
    const user = useContext(UserContext)
    const { t } = useTranslation('translation')

    const { memberId, skillId } = useParams()

    const verifyToken = useVerifyCoachToken()

    const [comment, setComment] = useState<string>()
    const [status, setStatus] = useState<SkillStatusEnum>()

    const lng = useLangSelected()

    const navigate = useNavigate()
    const BACK_LINK = `/${lng}/bva-coach/mes-membres/${memberId}`

    const { data: member } = useQuery({
        queryKey: ['coach-member'],
        queryFn: () =>
            axios.get<UserModel>(`${SERVER_URL}/users/${memberId}`, apiHeader(user.tokenCoach)).then((res) => res.data),
    })

    const { data: skill } = useQuery({
        queryKey: ['member-skill'],
        queryFn: () =>
            axios.get<Skill>(`${SERVER_URL}/skills/${skillId}`, apiHeader(user.tokenCoach)).then((res) => res.data),
    })

    const { mutate: updateSkill } = useMutation({
        mutationFn: (data: Partial<Skill>) =>
            axios.patch(`${SERVER_URL}/skills/${skillId}`, data, apiHeader(user.tokenCoach)).then(() => {
                toast.success(`Le Smash de ${member?.firstName} a bien été mis à jour.`)
                navigate(BACK_LINK)
            }),
    })

    const submit = useCallback(async () => {
        verifyToken()

        updateSkill({
            comment,
            status,
        })
    }, [comment, status])

    if (!member || !skill) return <Loader />

    return (
        <div>
            <TitleWrapper>
                <Title>
                    {member.firstName} {member.lastName} - {skill.name}
                </Title>

                <StyledButtonLink to={`/${lng}/bva-coach/mes-membres/${memberId}`} className="btn btn-primary mb-2">
                    {t('global.back')}
                </StyledButtonLink>
            </TitleWrapper>
            <AdminContainer>
                <AdminForm>
                    <Form.Group className="mb-3">
                        <Label>Commentaire</Label>
                        <Form.Control
                            type="text"
                            placeholder="Commentaire"
                            onChange={(e) => setComment(e.target.value)}
                            value={comment ?? skill.comment}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Statut *</Label>
                        <Form.Select
                            onChange={(e) => setStatus(e.target.value as SkillStatusEnum)}
                            value={status ?? skill.status ?? 'placeholder'}
                        >
                            <option value={'placeholder'} disabled>
                                Sélectionnez un statut
                            </option>
                            <option value={SkillStatusEnum.TO_DISCOVER}>À découvrir</option>
                            <option value={SkillStatusEnum.STARTED}>Commencé</option>
                            <option value={SkillStatusEnum.CONFIRMED}>Confirmé</option>
                            <option value={SkillStatusEnum.MASTERED}>Maîtrisé</option>
                        </Form.Select>
                    </Form.Group>
                    <StyledButton variant="primary" type="button" onClick={submit}>
                        Modifier
                    </StyledButton>
                </AdminForm>
            </AdminContainer>
        </div>
    )
}

export default CoachMyMembersSingleMemberSkillsForm
