import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/global/Loader'
import News from '../../components/global/News'
import SkillCard from '../../components/global/SkillCard'
import Title from '../../components/global/Title'
import { SERVER_URL, apiHeader } from '../../config'
import UserContext from '../../context/UserContext'
import { useLangSelected } from '../../hooks/useLangSelected'
import { serverFile } from '../../services/file.service'
import { NewsModel } from '../../types/news.type'
import { Skill } from '../../types/skill.type'
import { SkillsWrapper } from '../coach/my-members/CoachMyMembers'
import { CardContainer } from '../global/Home'

const MemberDashboard = () => {
    const user = useContext(UserContext)
    const { t } = useTranslation('translation')

    const lng = useLangSelected()

    const { data: news } = useQuery({
        queryKey: ['news'],
        queryFn: () =>
            axios
                .post<NewsModel[]>(`${SERVER_URL}/news/private`, { lng }, apiHeader(user.tokenMember))
                .then((res) => res.data),
    })

    const { data: myFavoriteSkills, refetch: refetchMyFavoriteSkills } = useQuery({
        queryKey: ['myFavoriteSkills'],
        queryFn: () =>
            axios
                .get<Skill[]>(
                    `${SERVER_URL}/skills/users/${user.memberData.licenseNumber}?isFavorite=true`,
                    apiHeader(user.tokenMember)
                )
                .then((res) => res.data),
    })

    if (!user.memberData || !myFavoriteSkills || !news) return <Loader />

    return (
        <div>
            <Title>{t('member.dashboard')}</Title>
            <TextContainer>
                <p>
                    {t('member.welcome-1', {
                        user: {
                            firstname: user.memberData.firstname,
                            lastname:
                                user.memberData.lastname[0].toUpperCase() +
                                user.memberData.lastname.substring(1).toLowerCase(),
                        },
                    })}
                    <br />
                    {t('member.welcome-2')}
                    <br />
                    {t('member.welcome-3')}
                    <br />
                    {t('member.welcome-4')}
                    <br />
                    {t('member.welcome-5')}
                </p>
            </TextContainer>
            {myFavoriteSkills.length > 0 && (
                <>
                    <Title>{t('member.my-followed-skills')}</Title>
                    <SkillsWrapper>
                        {myFavoriteSkills.map((skill: any) => (
                            <SkillCard key={skill.name} {...skill} refetchMySkills={refetchMyFavoriteSkills} />
                        ))}
                    </SkillsWrapper>
                </>
            )}
            <Title> {t('member.last-news')}</Title>
            <CardContainer>
                {news ? (
                    news.map((news: NewsModel) => (
                        <News
                            key={news.id}
                            title={news.title}
                            type={news.type}
                            description={news.description}
                            image={serverFile(news.image)}
                            link={news.link}
                        />
                    ))
                ) : (
                    <Loader />
                )}
            </CardContainer>
        </div>
    )
}

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    border-radius: 6px;
    background-color: white;
    margin-bottom: 16px;
`

export default MemberDashboard
