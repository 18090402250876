import styled from '@emotion/styled'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Label from '../../../components/form/Label'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { StyledButton } from '../Login'
import PasswordForm from '../users/components/PasswordForm'
import { AdminContainer, TitleWrapper } from './AdminCoach'

const AdminCoachForm = () => {
    const user = useContext(UserContext)
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const isAddForm = location.pathname.includes(`/${lng}/bva-admin/coachs/creer`)

    const [email, setEmail] = useState('')

    const [lastPassword, setLastPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [tel, setTel] = useState('')
    const [firstname, setFirstname] = useState('')

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        verifyToken()
        if (!isAddForm && isLoading) {
            axios
                .get(`${SERVER_URL}/coachs/${params.id}`, apiHeader(user.tokenAdmin))
                .then((res) => {
                    setEmail(res.data.email)
                    setFirstname(res.data.firstName)
                    setTel(res.data.tel)
                    setIsLoading(false)
                })
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            setIsLoading(false)
        }
    }, [isAddForm, isLoading, user.tokenAdmin, params.id])

    if (isLoading) return <Loader />

    const isEmailValidate = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
    const isPasswordValidate = isAddForm ? password.length > 3 : true
    const isConfirmPasswordValidate = isAddForm ? confirmPassword.length > 3 : true
    const isLastPasswordValidate = isAddForm ? lastPassword.length > 3 : true
    const isFirstnameValidate = !!firstname

    const isValidate = () => {
        if (isAddForm) {
            return isEmailValidate && isFirstnameValidate && isConfirmPasswordValidate && password === confirmPassword
        }

        return (
            isEmailValidate &&
            isFirstnameValidate &&
            ((isPasswordValidate && isLastPasswordValidate) || (!isPasswordValidate && !isLastPasswordValidate))
        )
    }

    const submit = () => {
        verifyToken()
        const data = {
            email,
            password,
            firstName: firstname,
            tel,
        }

        if (isAddForm) {
            axios
                .post(`${SERVER_URL}/coachs`, data, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`Le coach a bien été créé.`).then(() => {
                        navigate(`/${lng}/bva-admin/coachs`)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            axios
                .patch(`${SERVER_URL}/coachs/${params.id}`, data, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`Le coach a bien été modifié.`).then(() => {
                        navigate(`/${lng}/bva-admin/coachs`)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        }
    }

    return (
        <>
            <TitleWrapper>
                <Title>{isAddForm ? 'Créer' : 'Modifier'} un coach</Title>
                <StyledButtonLink to={`/${lng}/bva-admin/coachs`} className="btn btn-primary mb-2">
                    Retour
                </StyledButtonLink>
            </TitleWrapper>
            <AdminContainer>
                <AdminForm>
                    <Form.Group className="mb-3">
                        <Label>Email *</Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Label>Prénom *</Label>
                        <Form.Control
                            type="firstname"
                            placeholder="Prénom"
                            onChange={(e) => setFirstname(e.target.value)}
                            value={firstname}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Label>N° de Téléphone</Label>
                        <Form.Control
                            type="tel"
                            placeholder="N° de Téléphone"
                            onChange={(e) => setTel(e.target.value)}
                            value={tel}
                        />
                    </Form.Group>

                    <PasswordForm
                        isAddForm={isAddForm}
                        confirmPassword={confirmPassword}
                        lastPassword={lastPassword}
                        password={password}
                        setConfirmPassword={setConfirmPassword}
                        setLastPassword={setLastPassword}
                        setPassword={setPassword}
                    />

                    <StyledButton variant="primary" type="button" disabled={!isValidate()} onClick={submit}>
                        {isAddForm ? 'Créer' : 'Modifier'}
                    </StyledButton>
                </AdminForm>
            </AdminContainer>
        </>
    )
}

export const AdminForm = styled(Form)`
    width: 100%;

    input,
    select {
        font-size: 1.6rem;
    }
`

export default AdminCoachForm
