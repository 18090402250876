const frTranslation = () => {
    return {
        global: {
            monday: 'Lundi',
            tuesday: 'Mardi',
            wednesday: 'Mercredi',
            thursday: 'Jeudi',
            friday: 'Vendredi',
            saturday: 'Samedi',
            sunday: 'Dimanche',
            lang: {
                fr: 'France (French)',
                gb: 'Anglais (English)',
                br: 'Brésil (Brazil)',
            },
            back: 'Retour',
        },
        home: {
            'main-title': "Badminton Villeneuve d'Ascq",
            'presentation-1': 'Jeunes et adultes, compétiteurs et loisirs, ',
            'presentation-2': 'le ',
            'presentation-3': 'BVA',
            'presentation-4': ' accompagne tous les joueurs de badminton à prendre du plaisir.',
            'last-news': 'Derniers articles',
        },
        header: {
            menu: {
                home: 'Accueil',
                planning: 'Horaires & Tarifs',
                registration: 'Inscription',
                competition: 'Compétitions',
                contact: 'Contactez-nous',
                'my-space-bva': 'Mon espace BVA',
                login: 'Se connecter',
            },
            'my-tournament-account': 'Mon compte tournoi',
            'tournament-account-message':
                "Le compte tournoi commence à 0€ et peut atteindre 25€ maximum. Il concerne uniquement la Licence Compétiteur. Pour le remplir rien de plus simple, il faut participer aux entraînements et prévenir en cas d'absence.",
        },
        footer: {
            copyright: 'Copyright © 2022. Tous droits réservés - Site créé par',
            administration: 'Administration',
        },
        sidebar: {
            admin: {
                dashboard: 'Tableau de bord',
                user: 'Utilisateurs',
                news: 'Articles',
                competition: 'Compétitions',
                plannings: 'Plannings',
                prices: 'Tarifs',
                documentation: 'Documentation',
                'bva-bot': 'BVA bot',
                'to-document-myself': 'Me documenter',
                coachs: 'Coachs',
                'training-sessions': "Sessions d'entraînement",
            },
            member: {
                'my-progression': 'Ma progression',
                password: 'Mot de passe',
            },
        },
        admin: {
            dashboard: 'Tableau de bord',
            or: 'ou',
            'search-member-input': '🔍 Rechercher un membre',
            'search-coach-input': '🔍 Rechercher un coach',
        },
        coach: {
            dashboard: 'Tableau de bord',
            'my-members': 'Mes membres',
            'select-members': 'Sélectionner des membres',
            'members-added': 'Membres ajoutés',
            skills: 'Compétences',
            'skill-already-exist': 'Cette competence existe deja',
            'add-skill': 'Ajouter une compétence',
            'skill-edited': 'Compétence modifiée',
            'skill-deleted': 'Compétence supprimée',
            'members-removed': 'Membres retirés',
            '4-players-with-most-missings': 'Les 4 joueurs avec le plus de lacunes',
            'skills-least-mastered': 'Les compétences les moins maîtrisées',
        },
        member: {
            dashboard: 'Tableau de bord',
            'my-followed-skills': 'Mes compétences suivies',
            'my-progression': 'Ma progression',
            'my-skills': 'Mes compétences',
            documentation: 'Documentation',
            'search-document-input': '🔍 Rechercher un document',
            'search-skill-input': '🔍 Rechercher une compétence',
            'welcome-1': 'Bonjour {{ user.firstname }} {{ user.lastname }},',
            'welcome-2': 'Bienvenue dans ton espace BVA.',
            'welcome-3': "C'est ici qu'est affiché ton compte tournoi (utile pour la Licence Compétiteur).",
            'welcome-4': 'Dans le futur, de nouvelles fonctionnalités peuvent faire leur apparition.',
            'welcome-5':
                "Si tu as des idées pour faire évoluer le site, n'hésite pas à les envoyer à l'adresse mail : president@bva-club.fr.",
            'last-news': 'Derniers articles',
            'change-password-title': 'Modifier mon mot de passe',
            'current-password-label': 'Mot de passe actuel',
            'new-password-label': 'Nouveau mot de passe',
            'confirm-password-label': 'Confirmer le nouveau mot de passe',
        },
        error: {
            'session-expired': 'Votre session a expiré, vous allez être déconnecté par mesure de sécurité.',
        },
        page: {
            planning: {
                joyride: {
                    body: {
                        title: 'Horaires & Tarifs',
                        content: "Petite visite guidée pour savoir où poser l'œil et ne rien rater !",
                    },
                    'help-btn': {
                        title: 'Bouton Guidez-moi !',
                        content: 'Ce bouton relance la visite guidée.',
                    },
                    'planning-holidays': {
                        title: 'Planning des vacances scolaires',
                        content: 'Ce planning correspond aux séances disponibles durant les vacances scolaires !',
                    },
                    'planning-summer': {
                        title: "Planning des vacances d'été",
                        content: "Ce planning correspond aux séances disponibles durant les vacances d'été !",
                    },
                    legend: {
                        title: 'La légende',
                        content:
                            'Chaque planning contient la légende cette légende vous aidera a mieux comprendre le planning.',
                    },
                    planning: {
                        title: 'Planning principal',
                        content: "Ce planning correspond aux séances principales qui auront lieu durant toute l'année.",
                    },
                    try: {
                        title: "Les séances d'essai",
                        content: 'Si vous êtes nouveau ça devrait vous intéresser !',
                    },
                    'our-prices': {
                        title: 'Nos licences',
                        content:
                            "Une fois que vous avez effectué au moins une séance d'essais, vous pouvez nous demander le formulaire d'inscription. Dans celui-ci, vous pourrez choisir la licence qui vous correspond ainsi que le moyen de paiement souhaité.",
                    },
                },
                'summer-sessions':
                    "Les créneaux d'été sont ouverts à tous pendant les vacances d'été. C'est le bon moment pour venir essayer et s'amuser avec nous sur les terrains.",
            },
            contact: {
                title: 'Contactez-nous',
            },
            competition: {
                title: 'Compétitions',
                'badnet-register': "S'inscrire sur BadNet",
                'tournament-account-register': "S'inscrire avec le compte tournoi",
                'need-login': 'Pour utiliser ton compte tournoi tu dois être connecté',
                'no-data':
                    "Aucune compétition pour le moment. Nous allons les ajouter lorsqu'elles seront disponibles.",
            },
            price: {
                title: 'Tarifs de la saison 2024/2025',
                'title-try': "Séances d'essai",
                'price-try-1': "Avant de vous inscrire vous avez droit à 3 séances d'essai.",
                'price-try-2': 'Durant celle-ci nous pouvons vous prêter des raquettes et des volants.',
                'price-try-3': 'Au-delà, il vous faudra ramener vos raquettes et vos volants.',
                'register-info-date':
                    'Les inscriptions ouvriront le 1er août pour les renouvellements des membres déjà inscrits. Et dès le 2 septembre 2024 pour les nouveaux membres.',
                'register-info-competition':
                    'Toutes nos licences offrent la possibilité de participer à des compétitions.',
                'warning-off-day': '⚠️ Attention : Le club ferme toujours lors des jours fériés ! ⚠️',
            },
            login: {
                connexion: 'Connexion',
                member: 'Membre',
                coach: 'Coach',
                admin: 'Administrateur',
            },
        },
        joyride: {
            help: 'Guidez-moi !',
            next: 'Suivant',
            last: 'Terminer la visite',
            skip: 'Fermer',
            back: 'Précédent',
            close: 'Fermer',
        },
        bot: {
            init: 'Bonjour ! Que puis-je faire pour vous ? Vous pouvez me poser des petites questions ou utiliser des mots clés, par exemple le mot "horaires" vous redirigera vers la page horaires et tarifs. Pour tout message long merci de contacter le BVA à l\'adresse mail suivante : contact@bva-club.fr',
        },
        cookies: "Ce site utilise des cookies qui ne durent qu'un jour afin de mesurer l'audience.",
        skill: { TO_DISCOVER: 'À découvrir', STARTED: 'Commencé', CONFIRMED: 'Confirmé', MASTERED: 'Maîtrisé' },
    }
}

export default frTranslation
