import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Loader from '../../../components/global/Loader'
import SkillCard from '../../../components/global/SkillCard'
import Title from '../../../components/global/Title'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { CoachModel } from '../../../types/coach.type'
import { Skill } from '../../../types/skill.type'
import { TitleWrapper } from '../../admin/coach/AdminCoach'
import { StyledButton } from '../../admin/Login'
import { SkillsWrapper } from '../my-members/CoachMyMembers'
import CoachSkillFormModal from './CoachSkillFormModal'

export default function CoachSkills() {
    const user = useContext(UserContext)
    const { t } = useTranslation()

    const [skillToEdit, setSkillToEdit] = useState<Skill>()
    const [showEditSkillModal, setShowEditSkillModal] = useState(false)

    const lng = useLangSelected()

    const { data: skills, refetch: refetchCoachSkills } = useQuery({
        queryKey: ['coach-skills'],
        queryFn: async () => {
            const response = await axios.get<CoachModel>(
                `${SERVER_URL}/coachs/${user.coachData.id}`,
                apiHeader(user.tokenCoach)
            )
            return response.data.users?.[0].skills ?? []
        },
    })

    const { mutate: deleteSkill } = useMutation({
        mutationFn: async (id: number) => {
            await axios.delete(`${SERVER_URL}/skills/${id}`, apiHeader(user.tokenCoach))
            refetchCoachSkills()
            toast.success(t('coach.skill-deleted'))
        },
    })

    if (!skills) return <Loader />

    const editSkill = (skill: Skill) => {
        setSkillToEdit(skill)
        setShowEditSkillModal(true)
    }

    return (
        <div>
            <TitleWrapper>
                <Title>{t('coach.skills')}</Title>
                <StyledButton
                    className="btn btn-primary"
                    onClick={() => {
                        setShowEditSkillModal(true)
                    }}
                >
                    Créer une compétence
                </StyledButton>
            </TitleWrapper>
            <SkillsWrapper>
                {skills.map((skill) => (
                    <SkillCard
                        key={skill.id}
                        name={skill.name}
                        deleteFn={() => deleteSkill(skill.id)}
                        editFn={() => editSkill(skill)}
                    />
                ))}
            </SkillsWrapper>
            {showEditSkillModal && (
                <CoachSkillFormModal
                    refetchCoachSkills={refetchCoachSkills}
                    setShowEditSkillModal={setShowEditSkillModal}
                    skillToEdit={skillToEdit}
                    setSkillToEdit={setSkillToEdit}
                    skills={skills}
                />
            )}
        </div>
    )
}
