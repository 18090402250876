import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { useContext, useMemo, useState } from 'react'
import { Badge, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Breakpoint from '../../../Breakpoint'
import { getAllCoachs } from '../../../api/coach.api'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import SearchInput from '../../../components/global/SearchInput'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { CoachModel } from '../../../types/coach.type'

const filteredCoachs = (coachs: CoachModelForTable[], res: AxiosResponse<any, any>) =>
    coachs.filter((coach: { id: number }) => coach.id !== res.data.id)

type CoachModelForTable = CoachModel & {
    edit: string
    remove: boolean
}

const AdminCoach = () => {
    const { t } = useTranslation('translation')

    const [searchTerm, setSearchTerm] = useState('')

    const user = useContext(UserContext)

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()

    const tableHeaderMember = ['#', 'Prénom', 'Email', 'Téléphone', '', '']

    const { data: coachs, refetch: refetchCoachs } = useQuery({
        queryKey: ['coach-all'],
        queryFn: async () => {
            verifyToken()
            const coachs = (await getAllCoachs()) as CoachModel[]

            return coachs.map((coach) => ({
                id: coach.id,
                firstname: coach.firstName,
                email: coach.email,
                tel: coach.tel,
                edit: `/${lng}/bva-admin/coachs/${coach.id}`,
                remove: true,
            }))
        },
    })

    const coachFiltered = useMemo(
        () =>
            coachs?.filter((coach) =>
                `${coach.firstname} ${coach.email}`.toLowerCase().includes(searchTerm.toLowerCase())
            ) ?? [],
        [coachs, searchTerm]
    )

    if (!coachs) return <Loader />

    const deleteById = (id: number) => {
        verifyToken()
        axios
            .delete(`${SERVER_URL}/coachs/${id}`, apiHeader(user.tokenAdmin))
            .then(() => {
                sweetAlertSuccess('La suppression du coach est réussi').then(() => {
                    refetchCoachs()
                })
            })
            .catch((err) =>
                catchError(err.response.data, () => {
                    localStorage.removeItem('tokenAdmin')
                    user.adminData = undefined
                    window.location.reload()
                })
            )
    }

    return (
        <>
            <TitleWrapper>
                <Title>Coachs</Title>
                <HeaderBtnWrapper>
                    <LinkBtn to={`/${lng}/bva-admin/coachs/creer`} className="btn btn-primary">
                        Créer un coach
                    </LinkBtn>
                </HeaderBtnWrapper>
            </TitleWrapper>

            <AdminContainer>
                <TitleWrapper>
                    <TableTitle>
                        Liste des coachs <BvaBadge>{coachs.length}</BvaBadge>
                    </TableTitle>{' '}
                </TitleWrapper>
                <SearchInput
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={t('admin.search-coach-input')}
                />
                <Table tableData={coachFiltered} tableHeader={tableHeaderMember} deleteById={deleteById} />
            </AdminContainer>
        </>
    )
}

export const StyledButtonA = styled.a`
    font-size: 1.6rem;

    &:active {
        transform: scale(0.95);
    }
`

const HeaderBtnWrapper = styled.div`
    display: flex;

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        flex-direction: column;
    }
`

const IconBtn = styled(FontAwesomeIcon)`
    font-size: 2rem;
`

const BtnWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 8px;
    width: 75%;

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        flex-direction: column;
        width: 100%;
        flex-wrap: nowrap;
    }
`

const BvaBadge = styled(Badge)`
    display: flex;
    align-items: center;
    width: min-content;
    height: 28px;
    background: var(--primary-color) !important;
    margin-left: 8px;
`

const CoachTableWrapper = styled.div`
    margin-bottom: 32px;
`

const TableTitle = styled(Title)`
    display: flex;
    width: 25%;
    align-items: center;
    margin-bottom: 8px;

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        width: 100%;
    }
`

export const LinkBtn = styled(StyledButtonLink)`
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 1.6rem;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        margin-right: 0;
    }
`

const StyledButton = styled(Button)`
    font-size: 1.6rem;

    &:active {
        transform: scale(0.95);
    }
`

const Btn = styled(StyledButton)`
    margin-right: 8px;
    margin-bottom: 8px;

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        margin-right: 0;
        width: 100%;
    }
`

const ImportInput = styled.input`
    display: block;
    width: 100%;
    webkit-appearance: none;
    margin-right: 8px;
    margin-top: 16px;
    cursor: pointer;
    margin-bottom: 16px;
`

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    & .btn {
        height: 38px;
    }

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        flex-direction: column;
    }
`

export const AdminContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 278px);
    padding: 24px;
    border-radius: 6px;
    background-color: white;
`

export default AdminCoach
