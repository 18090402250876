import styled from '@emotion/styled'
import { Dropzone, ExtFile, FileMosaic } from '@files-ui/react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Document, Page } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Breakpoint from '../../../Breakpoint'
import Label from '../../../components/form/Label'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import { TextAreaWysiwyg } from '../../../components/global/TextAreaWysiwyg'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { serverFile } from '../../../services/file.service'
import { StyledButton } from '../Login'
import { AdminContainer, TitleWrapper } from '../users/AdminUsers'
import { AdminForm } from '../users/AdminUsersForm'

const AdminDocumentationForm = () => {
    const user = useContext(UserContext)
    const location = useLocation()
    const params = useParams()

    const { t } = useTranslation('translation')

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentLang, setCurrentLang] = useState(lng)

    const navigate = useNavigate()
    const BACK_LINK = `/${lng}/bva-admin/documentation`

    const [title, setTitle] = useState('')
    const [type, setType] = useState('placeholder')

    const [description, setDescription] = useState('')
    const [files, setFiles] = useState<ExtFile[]>([])
    const [docUrl, setDocUrl] = useState('')

    const [currentDoc, setCurrentDoc] = useState<string | undefined>('')
    const [numPages, setNumPages] = useState<number>()
    const [pageNumber, setPageNumber] = useState<number>(1)

    const [isLoading, setIsLoading] = useState(true)

    const isAddForm = location.pathname.includes('/bva-admin/documentation/creer')

    useEffect(() => {
        verifyToken()
        if ((!isAddForm && isLoading) || currentLang !== lng) {
            setCurrentLang(lng)
            axios
                .post(`${SERVER_URL}/documents/one/${params.id}`, { lng }, apiHeader(user.tokenAdmin))
                .then((res) => {
                    setTitle(res.data.title)
                    setType(res.data.type)
                    setDescription(res.data.description)
                    setCurrentDoc(res.data.file.includes('http') ? res.data.file : serverFile(res.data.file))
                    setDocUrl(res.data.file.includes('http') ? res.data.file : serverFile(res.data.file))
                    setIsLoading(false)
                })
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            setIsLoading(false)
        }
    }, [isAddForm, isLoading, user.tokenAdmin, params.id, lng])

    if (isLoading || currentLang !== lng) return <Loader />

    const isTitleValidate = title.length > 2
    const isDescriptionValidate = description.length > 2
    const isFileValidate = files[0] || docUrl || currentDoc
    const isTypeValidate = type !== 'placeholder'

    const isValidate = isTitleValidate && isDescriptionValidate && isFileValidate && isTypeValidate

    const submit = () => {
        verifyToken()
        if (!files[0]?.file && !docUrl) return

        const formData = new FormData()

        formData.append('title', title)
        formData.append('type', type)
        formData.append('description', description)
        formData.append('docFile', (files[0]?.file || docUrl || currentDoc) as any)

        if (isAddForm) {
            axios
                .post(`${SERVER_URL}/documents`, formData, apiHeader(user.tokenAdmin, true))
                .then(() =>
                    sweetAlertSuccess(`Le document a bien été créé.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            formData.append('lng', lng)

            axios
                .patch(`${SERVER_URL}/documents/${params.id}`, formData, apiHeader(user.tokenAdmin, true))
                .then(() =>
                    sweetAlertSuccess(`Le document a bien été modifié.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        }
    }

    const updateFiles = (incomingFiles: ExtFile[]) => {
        if (incomingFiles.length === 0) {
            setDocUrl('')
            setCurrentDoc(undefined)
        }
        if (incomingFiles.length > 1) setFiles([incomingFiles[1]])
        else setFiles(incomingFiles)
    }

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages)
    }

    return (
        <>
            <TitleWrapper>
                <Title>{isAddForm ? 'Créer' : 'Modifier'} un document</Title>
                <StyledButtonLink to={BACK_LINK} className="btn btn-primary mb-2">
                    Retour
                </StyledButtonLink>
            </TitleWrapper>
            <AdminContainer>
                <AdminForm>
                    <Form.Group className="mb-3">
                        <Label>Titre du document *</Label>
                        <Form.Control
                            type="text"
                            placeholder="Titre du document"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Type du document *</Label>
                        <Form.Select onChange={(e) => setType(e.target.value)} defaultValue={type || 'placeholder'}>
                            <option value={'placeholder'} disabled>
                                Sélectionnez un type
                            </option>
                            <option value="MEMBER">Membre</option>
                            <option value="CA">CA</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Description *</Label>
                        <TextAreaWysiwyg value={description} setValue={setDescription} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Document</Label>
                        <FileWrapper>
                            <Form.Control
                                type="text"
                                placeholder="Url du document"
                                onChange={(e) => setDocUrl(e.target.value)}
                                value={docUrl}
                            />
                            <div style={{ marginTop: 16 }}>{t('admin.or').toUpperCase()}</div>
                            <DropzoneStyled onChange={updateFiles} value={files}>
                                {files.map((file: any) => (
                                    <FileMosaic key={file} {...file} preview />
                                ))}
                            </DropzoneStyled>
                            {(files?.[0]?.file || currentDoc) && (
                                <PdfContainer>
                                    <div style={{ position: 'relative' }}>
                                        <RemoveBtnContainer
                                            onClick={() => {
                                                setDocUrl('')
                                                setCurrentDoc(undefined)
                                                setFiles([])
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </RemoveBtnContainer>
                                        <DocumentPdf
                                            error="Une erreur s'est produite"
                                            loading="Chargement"
                                            noData="Aucun document"
                                            file={files?.[0]?.file || currentDoc}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            <Page pageNumber={pageNumber} />
                                        </DocumentPdf>
                                    </div>
                                    <PdfControls className="page-controls">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (pageNumber > 1) setPageNumber(pageNumber - 1)
                                            }}
                                        >
                                            {'<'}
                                        </button>
                                        <span>
                                            {pageNumber} sur {numPages}
                                        </span>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (pageNumber < numPages!) setPageNumber(pageNumber + 1)
                                            }}
                                        >
                                            {'>'}
                                        </button>
                                    </PdfControls>
                                </PdfContainer>
                            )}
                        </FileWrapper>
                    </Form.Group>
                    <StyledButton variant="primary" type="button" disabled={!isValidate} onClick={submit}>
                        {isAddForm ? 'Créer' : 'Modifier'}
                    </StyledButton>
                </AdminForm>
            </AdminContainer>
        </>
    )
}

const RemoveBtnContainer = styled.div`
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 3;
    display: flex;
    width: 100vw;
    justify-content: flex-end;
    padding: 24px 24px 24px 0;
    color: var(--primary-color);
    box-sizing: border-box;
    font-size: 3rem;

    & .fa-trash {
        cursor: pointer;
    }
`

const DropzoneStyled = styled(Dropzone)`
    margin-top: 16px;
`

const DocumentPdf = styled(Document)`
    width: min-content;
    box-shadow: 0 0px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 8px;
    height: 842px;
`

const PdfControls = styled.div`
    position: absolute;
    bottom: 1%;
    left: 50%;
    background: white;
    background-color: white;
    background-image: none;
    opacity: 1;
    transform: translate(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 0px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;

    z-index: 2;

    & button:first-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    & button {
        width: 44px;
        height: 44px;
        background: white;
        border: 0;
        font: inherit;
        font-size: inherit;
        font-size: 0.8em;
        border-radius: 4px;
    }
`

const PdfContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 16px;
`

const CurrentFilePreview = styled.div`
    display: flex;
    width: 36%;
    height: 250px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 16px;
    text-align: center;

    & img {
        width: 128px;
        height: 128px;
    }

    @media (max-width: ${Breakpoint.TABLET_L}) {
        margin-top: 16px;
        width: 100%;
        margin-left: 0;
    }
`

const FileWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export default AdminDocumentationForm
