import styled from '@emotion/styled'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { useLangSelected } from '../../hooks/useLangSelected'
import { SkillStatusEnum } from '../../types/skill.type'
import { skillStatusColors } from '../global/SkillCard'

interface CoachMemberCardProps {
    user: {
        id: number
        firstName: string
        lastName: string
        skills: {
            status: SkillStatusEnum
        }[]
    }
}

const CoachMemberCard: React.FC<CoachMemberCardProps> = ({ user }) => {
    const navigate = useNavigate()

    const lng = useLangSelected()

    const handleClick = () => {
        navigate(`/${lng}/bva-coach/mes-membres/${user.id}`)
    }

    const skillsByStatus = {
        [SkillStatusEnum.MASTERED]: user.skills.filter((skill) => skill.status === SkillStatusEnum.MASTERED),
        [SkillStatusEnum.CONFIRMED]: user.skills.filter((skill) => skill.status === SkillStatusEnum.CONFIRMED),
        [SkillStatusEnum.STARTED]: user.skills.filter((skill) => skill.status === SkillStatusEnum.STARTED),
        [SkillStatusEnum.TO_DISCOVER]: user.skills.filter((skill) => skill.status === SkillStatusEnum.TO_DISCOVER),
    }

    const progressBar = Object.keys(skillsByStatus).map((status) => {
        const color = skillStatusColors[status as SkillStatusEnum]
        const width = (skillsByStatus[status as SkillStatusEnum].length / user.skills.length) * 100

        return (
            <SkillProgressInner key={`progress-bar-${status}`} style={{ width: `${width}%`, backgroundColor: color }}>
                {skillsByStatus[status as SkillStatusEnum].length > 0
                    ? `${Math.floor(width)}% (${skillsByStatus[status as SkillStatusEnum].length})`
                    : ''}
            </SkillProgressInner>
        )
    })

    return (
        <CoachMemberCardContainer onClick={handleClick}>
            <MemberInfo>
                <div>
                    {user.firstName} {user.lastName}
                </div>
                <ChevronIcon icon={faChevronRight} />
            </MemberInfo>
            <MemberSkills>
                Compétences <SkillProgress>{progressBar}</SkillProgress>
            </MemberSkills>
        </CoachMemberCardContainer>
    )
}

const ChevronIcon = styled(FontAwesomeIcon)`
    font-size: 2rem;
`

const CoachMemberCardContainer = styled.div`
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;

    &:hover {
        background-color: #eee;
    }
`

const MemberInfo = styled.h3`
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 10px;
`

const MemberSkills = styled.div`
    font-size: 14px;
    color: #666;
`

const SkillProgress = styled.div`
    width: 100%;
    height: 30px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
`

const SkillProgressInner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
    transition: width 0.5s ease-in-out;
    overflow: hidden;
`

export default CoachMemberCard
