import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import Breakpoint from '../../Breakpoint'
import { SkillStatusEnum } from '../../types/skill.type'
import { skillStatusColors } from '../global/SkillCard'

type FilterStatusProps = {
    selectedStatus: SkillStatusEnum[]
    setSelectedStatus: (value: SkillStatusEnum[]) => void
}

const FilterStatus = ({ selectedStatus, setSelectedStatus }: FilterStatusProps) => {
    const { t } = useTranslation('translation')

    const handleToggleStatus = (status: SkillStatusEnum) => {
        if (selectedStatus.includes(status)) {
            setSelectedStatus(selectedStatus.filter((s) => s !== status))
        } else {
            setSelectedStatus([...selectedStatus, status])
        }
    }

    return (
        <FilterStatusContainer>
            {Object.values(SkillStatusEnum).map((status) => (
                <BadgeButton
                    key={status}
                    backgroundColor={
                        selectedStatus.includes(status) ? skillStatusColors[status] : skillStatusColors[status] + '55'
                    }
                    onClick={() => handleToggleStatus(status)}
                >
                    {t(`skill.${status}`)}
                </BadgeButton>
            ))}
        </FilterStatusContainer>
    )
}

const FilterStatusContainer = styled.div`
    display: flex;
    gap: 8px;

    @media (max-width: ${Breakpoint.PHONE}) {
        width: 100%;
        margin-right: 0;
        flex-wrap: wrap;
        flex-direction: column;
    }
`

const BadgeButton = styled.button<{ backgroundColor?: string }>`
    user-select: none;
    appearance: none;
    border: none;
    background-color: ${(props) => props.backgroundColor};
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;

    &:hover {
        opacity: 0.7;
    }
`

export default FilterStatus
