import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useContext, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Label from '../../../components/form/Label'
import { apiHeader, SERVER_URL } from '../../../config'
import UserContext from '../../../context/UserContext'
import { Skill } from '../../../types/skill.type'
import { StyledButton } from '../../admin/Login'
import { AdminForm } from '../../admin/users/AdminUsersForm'

type CoachSkillFormModalProps = {
    setShowEditSkillModal: (show: boolean) => void
    skillToEdit?: Skill
    setSkillToEdit: (skill?: Skill) => void
    skills: Skill[]
    refetchCoachSkills: () => void
}

const CoachSkillFormModal = ({
    setShowEditSkillModal,
    skillToEdit,
    setSkillToEdit,
    refetchCoachSkills,
    skills,
}: CoachSkillFormModalProps) => {
    const user = useContext(UserContext)
    const [name, setName] = useState<string>()

    const { t } = useTranslation('translation')

    const { mutate: editSkill } = useMutation({
        mutationFn: async () => {
            if (!validateForm()) {
                return toast.error(t('coach.skill-already-exist'))
            }

            await axios.patch(`${SERVER_URL}/skills/${skillToEdit?.id}`, { name }, apiHeader(user.tokenCoach))
            refetchCoachSkills()
            toast.success(t('coach.skill-edited'))
        },
    })

    const { mutate: createSkill } = useMutation({
        mutationFn: async () => {
            if (!validateForm()) {
                return toast.error(t('coach.skill-already-exist'))
            }

            await axios.post(`${SERVER_URL}/skills`, { name }, apiHeader(user.tokenCoach))

            toast.success(`${name} a bien été créé.`)
            refetchCoachSkills()
        },
    })

    const initForm = () => {
        setSkillToEdit(undefined)
        setShowEditSkillModal(false)
        setName('')
    }

    const validateForm = () => {
        if (!name) return false
        return !skills.find((skill) => skill.name === name)
    }

    return (
        <Modal
            centered
            show={true}
            onHide={() => {
                initForm()
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {skillToEdit ? 'Modifier' : 'Créer'} une compétence
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AdminForm
                    onSubmit={(e) => {
                        e.preventDefault()
                        if (!skillToEdit) createSkill()
                        else editSkill()
                        initForm()
                    }}
                >
                    <Form.Group className="mb-3">
                        <Label>Name *</Label>
                        <Form.Control
                            type="text"
                            placeholder="Name *"
                            onChange={(e) => setName(e.target.value)}
                            value={name ?? skillToEdit?.name ?? ''}
                        />
                    </Form.Group>
                    <StyledButton variant="primary" type="submit" disabled={!name}>
                        {skillToEdit ? 'Modifier' : 'Créer'}
                    </StyledButton>
                </AdminForm>
            </Modal.Body>
        </Modal>
    )
}

export default CoachSkillFormModal
