import styled from '@emotion/styled'
import parse from 'html-react-parser'
import Breakpoint from '../../Breakpoint'
import images from '../../assets/img/images'
import { NewsType } from '../../types/news.type'
import CardLink from './CardLink'

const News = ({
    title,
    type,
    description,
    image,
    link = '#',
}: {
    title: string
    type: NewsType
    description: string
    image: string
    link: string
}) => {
    return (
        <NewsContainer>
            <CoverImageContainer image={type === NewsType.MEETING ? images.reunion : image || images.logoBVA} />
            <NewsTitle>{title}</NewsTitle>
            <NewsDescription>{parse(description)}</NewsDescription>
            {link && (
                <NewsLinkContainer>
                    <CardLink href={link || '#'} target="_blank">
                        En savoir plus
                    </CardLink>
                </NewsLinkContainer>
            )}
        </NewsContainer>
    )
}

type CoverImageContainerProps = {
    image: string
}

const CoverImageContainer = styled.div`
    ${({ image }: CoverImageContainerProps) => `
        position: relative;
        width: 100%;
        min-height: 150px;
        height: 150px;
        background-image: url('${image}');
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    `}
`

const NewsTitle = styled.h2`
    font-size: 2.6rem;
    margin-top: 16px;
    margin-bottom: 8px;
    color: var(--primary-color);
    font-family: 'Roboto Semi Bold';
`

const NewsDescription = styled.div`
    text-align: justify;
    line-height: 22px;
    min-height: 130px;
    overflow: auto;
    padding-right: 13px;
    margin-bottom: 5px;
`

const NewsContainer = styled.article`
    position: relative;
    width: 382px;
    height: 450px;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 16px;
    border-radius: 6px;
    margin-right: 5px;
    box-sizing: border-box;
    margin-bottom: 16px;
    overflow: hidden;

    &:hover {
        box-shadow: 0px 0px 6px black;
    }

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        margin-bottom: 16px;
        width: 95%;
    }

    @media (max-width: ${Breakpoint.PHONE}) {
        margin-right: 0;
    }
`

const NewsLinkContainer = styled.div`
    display: flex;
    text-decoration: none;
    border-radius: 4px;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
`

export default News
