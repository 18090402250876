import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Breakpoint from '../../Breakpoint'
import Loader from '../../components/global/Loader'
import SearchInput from '../../components/global/SearchInput'
import SkillCard from '../../components/global/SkillCard'
import Title from '../../components/global/Title'
import FilterStatus from '../../components/member/FilterStatus'
import { SERVER_URL, apiHeader } from '../../config'
import UserContext from '../../context/UserContext'
import { Skill, SkillStatusEnum } from '../../types/skill.type'
import { UserModel } from '../../types/user.type'
import { SkillsWrapper } from '../coach/my-members/CoachMyMembers'

const MemberMyProgression = () => {
    const user = useContext(UserContext)
    const { t } = useTranslation('translation')

    const [searchTerm, setSearchTerm] = useState('')
    const [selectedStatus, setSelectedStatus] = useState<SkillStatusEnum[]>([
        SkillStatusEnum.TO_DISCOVER,
        SkillStatusEnum.STARTED,
        SkillStatusEnum.CONFIRMED,
        SkillStatusEnum.MASTERED,
    ])

    const { data: currentUser, refetch: refetchMySkills } = useQuery({
        queryKey: ['currentUser'],
        queryFn: () =>
            axios
                .get<UserModel>(`${SERVER_URL}/users/${user.memberData.id}`, apiHeader(user.tokenMember))
                .then((res) => res.data),
    })

    const skillsFiltered = useMemo(
        () =>
            currentUser?.skills
                ?.filter((skill) => `${skill.name}`.toLowerCase().includes(searchTerm.toLowerCase()))
                .filter((skill) => selectedStatus.includes(skill.status)) ?? [],
        [searchTerm, currentUser, selectedStatus]
    )

    if (!currentUser) return <Loader />

    return (
        <>
            <Title>{t('member.my-progression')}</Title>
            <FilterWrapper>
                <SearchInput
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={t('member.search-skill-input')}
                />
                <FilterStatus selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
            </FilterWrapper>
            <SkillsWrapper>
                {skillsFiltered.map((skill: Skill) => (
                    <SkillCard key={skill.id} {...skill} refetchMySkills={refetchMySkills} />
                ))}
            </SkillsWrapper>
        </>
    )
}

export const FilterWrapper = styled.div`
    display: flex;
    margin-bottom: 16px;
    gap: 16px;

    & > div {
        margin-bottom: 0;
        margin-right: 16px;
    }

    @media (max-width: ${Breakpoint.PHONE}) {
        flex-direction: column;
    }
`

export default MemberMyProgression
