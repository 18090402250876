import styled from '@emotion/styled'
import { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import Breakpoint from '../../Breakpoint'

const SearchInput = ({
    value,
    onChange,
    placeholder,
}: {
    value: string
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    placeholder: string
}) => {
    return (
        <SearchInputContainer>
            <Form.Group>
                <Form.Control placeholder={placeholder} onChange={onChange} value={value} title={placeholder} />
            </Form.Group>
        </SearchInputContainer>
    )
}

export const SearchInputContainer = styled.div`
    display: flex;
    margin-bottom: 16px;

    & > div {
        width: 100%;
    }

    @media (max-width: ${Breakpoint.PHONE}) {
        width: 100%;
        margin-right: 0;
    }

    .form-control {
        font-size: 16px;
        width: 280px;

        @media (max-width: ${Breakpoint.PHONE}) {
            width: 100%;
            margin-right: 0;
        }
    }
`

export default SearchInput
