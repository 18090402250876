import axios from 'axios'
import { useContext, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Label from '../../components/form/Label'
import catchError from '../../components/global/CatchError'
import Title from '../../components/global/Title'
import { sweetAlertSuccess } from '../../components/sweetalert/Alert'
import { SERVER_URL, apiHeader } from '../../config'
import UserContext from '../../context/UserContext'
import { useVerifyMemberToken } from '../../hooks/useVerifyMemberToken'
import { StyledButton } from '../admin/Login'
import { AdminContainer } from '../admin/users/AdminUsers'
import { AdminForm } from '../admin/users/AdminUsersForm'

const CoachEditPassword = () => {
    const user = useContext(UserContext)

    const [lastPassword, setLastPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const { t } = useTranslation('translation')

    const verifyToken = useVerifyMemberToken()
    const navigate = useNavigate()

    const isPasswordValidate = password.length > 3
    const isConfirmPasswordValidate = confirmPassword.length > 3
    const isLastPasswordValidate = lastPassword.length > 3

    const submit = () => {
        verifyToken()
        const data = {
            lastPassword,
            password,
        }

        axios
            .patch(`${SERVER_URL}/coachs/${user.coachData.id}`, data, apiHeader(user.tokenCoach))
            .then(() =>
                sweetAlertSuccess(`Le mot de passe a bien été modifié.`).then(() => {
                    navigate('/bva-coach')
                })
            )
            .catch((err) => {
                catchError(err.response.data)
            })
    }

    const isValidate = () => {
        return isPasswordValidate && isLastPasswordValidate && isConfirmPasswordValidate && password === confirmPassword
    }

    return (
        <AdminContainer>
            <AdminForm>
                <Title>{t('member.change-password-title')}</Title>
                <Form.Group className="mb-3">
                    <Label>{t('member.current-password-label')} *</Label>
                    <Form.Control
                        type="password"
                        placeholder={t('member.current-password-label') ?? ''}
                        onChange={(e) => setLastPassword(e.target.value)}
                        value={lastPassword}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Label>{t('member.new-password-label')} *</Label>
                    <Form.Control
                        type="password"
                        placeholder={t('member.new-password-label') ?? ''}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Label>{t('member.confirm-password-label')} *</Label>
                    <Form.Control
                        type="password"
                        placeholder={t('member.confirm-password-label') ?? ''}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                    />
                </Form.Group>

                <StyledButton variant="primary" type="button" disabled={!isValidate()} onClick={submit}>
                    Modifier
                </StyledButton>
            </AdminForm>
        </AdminContainer>
    )
}

export default CoachEditPassword
